import { THREE, glsl } from 'gl'
import { React, styled } from 'x'
import { useRef, useState, useEffect } from 'react'
import { breakpoints } from 'styles'

const vertexShader = glsl`
  varying vec2 vUv;

  void main() {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`

const Wrapper = styled.div`
  width: ${ props => props.percentWidth }%;
  position: ${ props => props.header ? 'relative' : 'absolute'};
  background-color: rgb(24,24,24);
  top: 0;
  overflow: hidden;

  > canvas {
    margin-top: ${ props => props.header ? 0 : '25vh'};
    margin-bottom: -4px; // black line bottom of canvas, no idea where from
    position: relative;
    z-index: 1;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ props => props.viewWidth }vw;
  height: ${ props => props.viewHeight }vh;
  position: absolute;
`

const setup = ({ root, width, height, background, updateDOMElements }) => {
  const state = {
    animationRequest: null,
  }

  const scene = new THREE.Scene()
  scene.background = new THREE.Color(...background);

  const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000)
  camera.position.z = 500

  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
  renderer.setSize(width, height)

  root.appendChild(renderer.domElement)

  const textureLoader = new THREE.TextureLoader()
  const channelA = textureLoader.load('/img/channel-a.png')
  const channelB = textureLoader.load('/img/channel-b.jpg')

  const uniforms = {
    background: { type: 'vec3', value: background },
    iGlobalTime: { type: 'f', value: 154.0 },
    iChannel0: { type: 't', value: channelA },
    iChannel1: { type: 't', value: channelB },
  }

  uniforms.iChannel0.value.wrapS = uniforms.iChannel0.value.wrapT = THREE.RepeatWrapping
  uniforms.iChannel1.value.wrapS = uniforms.iChannel1.value.wrapT = THREE.RepeatWrapping

  const auroraMat = new THREE.ShaderMaterial( {
    uniforms: uniforms,
    vertexShader,

    fragmentShader: glsl`
      uniform vec3 background;
      uniform float iGlobalTime;
      uniform sampler2D iChannel0;
      uniform sampler2D iChannel1;
      varying vec2 vUv;

      #define TAU 6.2831853071

      vec3 hsb2rgb(in vec3 c) {
        vec3 rgb = clamp(abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0,0.0,1.0);
        rgb = rgb*rgb*(3.0-2.0*rgb);
        return c.z * mix(vec3(1.0), rgb, c.y);
      }

      float parabola(float x, float k) {
        return pow(4.0*x*(1.0-x), k);
      }

      void main() {
        vec2 uv = vUv;

        float o = texture2D(iChannel1, uv * 0.25 + vec2(0.0, iGlobalTime * 0.025)).r;
        float d = (texture2D(iChannel0, uv * 0.25 - vec2(0.0, iGlobalTime * 0.02 + o * 0.02)).r * 2.0 - 1.0);

        float v = uv.y + d * 0.1;
        v = 1.0 - abs(v * 2.0 - 1.0);
        v = pow(v, 2.5 + sin((iGlobalTime * 0.2 + d * 0.25) * TAU) * 0.5);

        vec3 start = vec3(mix(vec3(0.125, 0.125, 0.125), background, uv.y));
        vec3 color = start;
        gl_FragColor = vec4(color, 1.0);

        float x = (1.0 - uv.x * 0.75);
        float y = 1.0 - abs(uv.y * 2.0 - 1.0);
        //color += vec3(x * 0.5, y, x) * v; // Original
        color += hsb2rgb(vec3(abs(sin(iGlobalTime / 30.0 + x * 0.5)), 0.5, 0.8)) * v; // Color shift

        vec2 seed = vUv.xy;
        vec2 r;
        r.x = fract(sin((seed.x * 12.9898) + (seed.y * 78.2330)) * 43758.5453);
        r.y = fract(sin((seed.x * 53.7842) + (seed.y * 47.5134)) * 43758.5453);

        // float s = mix(r.x, (sin((iGlobalTime * 2.5 + 60.0) * r.y) * 0.5 + 0.5) * ((r.y * r.y) * (r.y * r.y)), 0.04);
        // color += pow(s, 70.0) * (1.0 - v);
        color += pow(0.0, 70.0) * (1.0 - v);
        vec3 mixed = mix(start, color, parabola(uv.y, 0.6));
        vec3 clipped = max(mixed, start);

        gl_FragColor = vec4(clipped, 1.0);
      }
    `,
  })
  auroraMat.transparent = true;

  const starMat = new THREE.ShaderMaterial({
    uniforms: uniforms,

    vertexShader,

    fragmentShader: glsl`
      uniform float iGlobalTime;
      varying vec2 vUv;

      void main() {
        vec2 seed = vUv.xy;
        vec2 r;
        r.x = fract(sin((seed.x * 12.9898) + (seed.y * 78.2330)) * 43758.5453);
        r.y = fract(sin((seed.x * 53.7842) + (seed.y * 47.5134)) * 43758.5453);

        float s = mix(r.x, (sin((iGlobalTime * 2.5 + 60.0) * r.y) * 0.5 + 0.5) * ((r.y * r.y) * (r.y * r.y)), 0.04);
        vec3 color = vec3(0.0);
        color += pow(s, 70.0) * (1.0);

        gl_FragColor.rgb = color;
        gl_FragColor.a = 1.0;
      }
     `
  })

  const clock = new THREE.Clock()

  const vFOV = THREE.Math.degToRad(camera.fov);
  const visibleHeight = 2 * Math.tan(vFOV / 2) * camera.position.z;
  const visibleWidth = height * camera.aspect;

  const auroraGeometry = new THREE.PlaneGeometry(visibleWidth, visibleHeight, 1, 1)
  const auroraPlane = new THREE.Mesh(auroraGeometry, auroraMat)

  auroraPlane.position.set(0,0,0)

  const starGeometry = new THREE.PlaneGeometry(width, height, 1, 1)
  const starPlane = new THREE.Mesh(starGeometry, starMat)

  // scene.add(starPlane)
  scene.add(auroraPlane)

  let getInTouch, hslElement, globalTime, colorPreview

  if (updateDOMElements) {
    getInTouch = document.getElementById('get-in-touch')
    hslElement = document.getElementById('hsl')
    globalTime = document.getElementById('global-time')
    colorPreview = document.getElementById('color-preview')
  }

  const animate = () => {
    state.animationRequest = requestAnimationFrame(animate)

    uniforms.iGlobalTime.value += clock.getDelta()

    if (updateDOMElements) {
      const hue = Math.abs(Math.sin((uniforms.iGlobalTime.value + 105) / 30)) * 359

      getInTouch.style.backgroundColor = `hsl(${hue}, 50%, 80%)`
      // colorPreview.style.backgroundColor = `hsl(${hue}, 100%, 80%)`
      // colorPreview.style.backgroundColor = `hsl(${hue}, 100%, 100%)`

      // hslElement.textContent = Math.round(hue)
      // globalTime.textContent = uniforms.iGlobalTime.value.toFixed(2)
    }

    renderer.render(scene, camera)
  }

  animate()

  const onWindowResize = () => {
    if (window.innerWidth < breakpoints.sm) {
      return
    }
    camera.aspect = window.innerWidth / window.innerHeight * 1.5
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight * 1.5)
  }

  return [() => {
    scene.remove(starPlane)
    scene.remove(auroraPlane)

    auroraGeometry.dispose()
    starGeometry.dispose()
    channelA.dispose()
    channelB.dispose()
    starMat.dispose()
    auroraMat.dispose()

    cancelAnimationFrame(state.animationRequest)

    root.removeChild(renderer.domElement)
  }, onWindowResize]
}

const Three = props => {
  const ref = useRef(null)

  const { background = [0.094, 0.094, 0.094] } = props

  const [{ width, height, screenHeight }, setSize] = useState({
    width: 100, //vw
    height: props.header ? 100 : 175, //vh
    screenHeight: 100 //vh
  })

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    if (!ref.current) {
      return
    }

    const rendererWidth = window.innerWidth
    const rendererHeight = props.header ? window.innerHeight : window.innerHeight * 1.5

    const [destroy, rendererOnResize] = setup({
      background: background,
      root: ref.current,
      width: rendererWidth,
      height: rendererHeight,
      updateDOMElements: !props.header
    })

    const onWindowResize = () => {
      rendererOnResize()
      // setSize({
      //   screenHeight: window.innerHeight
      // })
    }

    window.addEventListener('resize', onWindowResize, false);

    return () => {
      destroy()
      window.removeEventListener('resize', onWindowResize, false)
    }
  }, [])

  return (
    <Wrapper ref={ ref } percentWidth={ width } header={props.header}>
      <Content viewWidth={ width } viewHeight={ screenHeight }>
        { props.children }
      </Content>
    </Wrapper>
  )
}

export default Three
