import { React, styled, mq } from 'x'

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 8px;
`

const Text = styled.div`
  display: block;
  font-family: "font-VgXiPuvw", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 60px;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: ${ props => props.textColor || '#fff' };
  ${ props => props.alignment === 'right' ? 'text-align: right;' : '' }

  ${ mq.below('sm') } {
    text-align: left;
  }
`

/*
left: ${ props =>
  props.alignment === 'center'
    ? '50%'
  props.alignment === 'center'
  : 0
};
transform: translateX(${ props =>
  props.alignment === 'center'
    ? '-50%' : 0
});
*/

const Line = styled.div`
  position: absolute;

  ${ props =>
    props.alignment === 'center'
      ? 'left: 50%; transform: translateX(-50%);'
    : props.alignment === 'right'
      ? 'right: 0;'
    : 'left: 0;'
  }
  bottom: 0;
  display: block;
  width: 30px;
  height: 2px;
  background-color: ${ props => props.lineColor || '#fafafa' };

  ${ mq.below('sm') } {
    ${ props => props.alignment !== 'center' && 'left: 0; right: auto;' }
  }
`

const Label = props => (
  <Wrapper className={ props.className } style={ props.style }>
    <Text alignment={ props.alignment } textColor={ props.textColor }>
      { props.children }
    </Text>
    <Line alignment={ props.alignment } lineColor={ props.lineColor } />
  </Wrapper>
)

export default styled(Label)``
