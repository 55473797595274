import { theme } from 'styles'
import { Trans } from 'lingui'
import { React, styled, mq } from 'x'
import { default as Headroom } from 'react-headroom'
import { useReducer, useEffect, useCallback } from 'hooks'

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${ props => props.theme.headerHeight }px;
  padding: 20px 50px 0;
  box-sizing: border-box;
  z-index: 100;
  transition: background .37s;
  background: ${ props => props.hasScrolled
    ? 'linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))'
    : 'none'
  };
  
  ${ mq.below('sm') } {
    padding: 10px 20px 0;
  }
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const reducer = (hasScrolled, scrollY) => (
  scrollY > theme.headerHeight ? true : false
)

const Header = props => {
  const [hasScrolled, dispatch] = useReducer(
    reducer,
    typeof window !== "undefined" ? window.scrollY > theme.headerHeight : false
  )

  const handleScroll = useCallback(e => {
    dispatch(window.scrollY)
  })

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    window.addEventListener('scroll', handleScroll, false)

    return () => {
      window.removeEventListener('scroll', handleScroll, false)
    }
  }, [])

  return (
    <Headroom
      style={{
        height: `${ theme.headerHeight }px`,
        zIndex: 5,
      }}
      wrapperStyle={{
        height: `${ theme.headerHeight }px`,
        zIndex: 5,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <StyledHeader hasScrolled={ hasScrolled }>
        <HeaderContent>
          { props.children }
        </HeaderContent>
      </StyledHeader>
    </Headroom>
  )
}

export default Header
