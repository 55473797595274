export const colors = {
  salmon: '#ff4169',
  royalBlue: '#674eec',
  java: '#1adba2',
  blue: '#007bff',
  indigo: '#674eec',
  purple: '#8445f7',
  pink: '#ff4169',
  red: '#c4183c',
  orange: '#fb7906',
  yellow: '#ffb400',
  green: '#17c671',
  teal: '#1adba2',
  cyan: '#00b8d8',
  white: '#fff',
  silver: '#868e96',
  gray: '#343a40',

  primary: '#007bff',
  secondary: '#5a6169',
  success: '#17c671',
  info: '#00b8d8',
  warning: '#ffb400',
  danger: '#c4183c',
  light: '#fbfbfb',
  dark: '#212529',
  background: '#202020',
}

export default colors
