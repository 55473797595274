import { join } from 'utils'

export const fonts = {
  sans: join(',', [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Open Sans',
    'Helvetica Neue',
    'sans-serif'
  ]),

  mono: join(',', [
    'Space Mono',
    'SFMono-Regular',
    'Menlo',
    'Monaco',
    'Consolas',
    'Liberation Mono',
    'Courier New',
    'monospace'
  ]),

  serif: join(',', [
    'Times New Roman'
  ])
}

export default fonts
