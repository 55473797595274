import { MDXProvider } from '@mdx-js/react'
import { ThemeProvider } from 'styled-components'
import { ParallaxProvider } from 'react-scroll-parallax'
import { React } from 'x'
import { GlobalStyle, Article, theme } from 'styles'
import { Header, LanguageSwitcher, Logo, Navigation, Footer } from 'components'

const Design = props => {
  const language = props.pageContext.language
  const location = props.location.pathname
  const alternates = props.pageContext.alternates

  return (
    <ThemeProvider theme={ theme }>
      <MDXProvider components={ Article }>
        <GlobalStyle/>
        <ParallaxProvider>
          <Header>
            <Logo/>
            <Navigation/>
            <LanguageSwitcher
              language={ language }
              location={ location }
              alternates={ alternates }
            />
          </Header>
          <main>
            { props.children }
          </main>
          <Footer/>
        </ParallaxProvider>
      </MDXProvider>
    </ThemeProvider>
  )
}

export default Design
