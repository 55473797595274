import { React } from 'x'
import { assign, toKeys } from 'utils'
import { useRef, useState, useEffect, useCallback } from 'react'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

const useParallax = options => {
  const ref = useRef()
  const props = toKeys(options)

  const state = {
    raf: null,
    scrollY: 0,
    rootBounds: null,
    clientRect: null,
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const update = () => {
      assign(state, {
        raf: requestAnimationFrame(update),
      })

      const scrollY = window.scrollY

      if (state.scrollY === scrollY) {
        return
      } else {
        assign(state, {
          scrollY: scrollY,
        })
      }

      // console.log(scrollY - state.clientRect.top)
      // const bottom = state.clientRect.top + state.clientRect.height
      // const ratio = state.clientRect
      // window.scrollY
      // state.rootBounds.height
    }

    const callback = ([entry], observer) => {
      const {
        rootBounds,
        isIntersecting,
        intersectionRatio,
        boundingClientRect,
      } = entry

      assign(state, {
        rootBounds: rootBounds,
        clientRect: boundingClientRect,
      })

      if (isIntersecting) {
        assign(state, {
          raf: requestAnimationFrame(update),
        })
      } else {
        cancelAnimationFrame(state.raf)
      }
    }

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0,
      rootMargin: '0px',
    })

    observer.observe(ref.current)

  }, [])

  return ref
}

export default useParallax
