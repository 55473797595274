import { React, Link, styled, css } from 'x'

const ButtonStyle = css`
  display: inline-block;
  min-width: 205px;
  height: 40px;
  border: 2px solid #909090;
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 26px;
  letter-spacing: .05em;
  text-align: center;
  transition: border-color .25s;
  background: transparent;
  color: #FFF;
  cursor: pointer;
  padding: 0 20px;
  align-self: flex-start;
  box-sizing: border-box;

  &:hover {
    border-color: #FFF;
  }
`

const Button = styled.button`
  ${ ButtonStyle }
`

const LinkButton = styled(props => <Link {...props} />)`
  ${ ButtonStyle }

  line-height: 40px;
  text-decoration: none;
  margin-top: 6px;
`

export default Button

export { LinkButton }
