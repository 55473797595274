import { React, styled, mq } from 'x'
import { Logo } from 'components'
import { A } from 'common'

const Wrapper = styled.div`
  background-color: #0b0c10;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  ${mq.below('sm')} {
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
  }
`

const AbsoluteLogo = styled.div`
  position: absolute;
  left: 50px;

  ${mq.below('sm')} {
    left: 20px;
  }
`

const InfoWrapper = styled.div`
  ${mq.below('sm')} {
    display: flex;
    flex-flow: column;
  }
`

const Info = styled.span`
  color: #8c8c8c;
  font-size: 13px;
  margin: 0 10px;
  letter-spacing: 0.05em;
`

const Link = styled(A)`
  color: #8c8c8c;
`

const Footer = () => {
  return (
    <Wrapper>
      <AbsoluteLogo>
        <Logo height={18} />
      </AbsoluteLogo>
      <InfoWrapper>
        <Info>Chamber of Commerce: 72814012</Info>
        <Info>VAT Number: NL859246061B01</Info>
        <Info>IBAN: NL66BUNQ2032008033</Info>
        <Info>
          <Link href="/privacy-policy">Privacy</Link>
        </Info>
      </InfoWrapper>
    </Wrapper>
  )
}

export default Footer
