import { curry, reduce, isArray } from 'utils'

export const size = xs => (
  xs.length
)

export const join = curry((seperator, xs) => (
  xs.join(seperator)
))

export const randomItem = xs => (
  xs[Math.floor(Math.random() * (size(xs) - 1))]
)

export const flatten = xs => (
  reduce((out, x, i) => (
    isArray(x)
      ? out.push(...x)
      : out.push(x)
    , out
  ), [], xs)
)
