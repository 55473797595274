/*
export const random = (
  min = 0,
  max = min === 0 ? 1 : min,
  round = false),
*/

export const {
  cos,
  sin,
  tan,
  pow,
  abs,
  ceil,
  round,
  floor,
  random,
} = Math

export const randomFloat = (min, max) => (
  random() * (max - min) + min
)

export const randomInteger = (min, max) => (
  floor(random() * (max - min + 1) + min)
)
