import { React } from 'x'
import { useRef, useState, useEffect, useCallback } from 'react'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

const useReveal = ({
  delay = 0,
} = {}) => {
  const ref = useRef(null)

  const [isRevealed, setIsRevealed] = useState(false)

  const reveal = useCallback(() => {
    delay === 0
      ? setIsRevealed(true)
      : setTimeout(() => setIsRevealed(true), delay)
  })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const callback = ([{ isIntersecting }], observer) => {
      isIntersecting
        ? (observer.disconnect(), reveal())
        : null
    }

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0,
      rootMargin: '0px',
    })

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [])

  return {
    reveal: ref,
    isRevealed: isRevealed,
  }
}

export default useReveal
