import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html {
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-family:
      "Fira Sans",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Droid Sans",
      "Helvetica Neue",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol";

    background-color: ${ props => props.theme.background };
    margin: 0;
  }
  
  * {
    box-sizing: border-box;
  }
`
