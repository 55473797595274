import { default as React } from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
  children,
  to,
  ref,
  href,
  activeClassName,
  ...other
}) => {
  const extra = {}

  if (ref) {
    extra.ref = ref
  }

  const destination = to ? to : href ? href : '#'

  if (!destination || destination === '#') {
    console.warn('Invalid link')
  }

  const isInternal = /^\/(?!\/)/.test(destination)

  if (isInternal) {
    return (
      <GatsbyLink
        to={ destination }
        activeClassName={ activeClassName }
        { ...other }
        { ...extra }
      >
        { children }
      </GatsbyLink>
    )
  }

  return (
    <a
      href={ destination }
      { ...other }
      { ...extra }
    >
      { children }
    </a>
  )
}

export default Link
