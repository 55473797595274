export const noop = () => {}

export const pipe = (...fs) => x => (
  fs.reduce((y, f) => f(y), x)
)

export const compose = (...fs) => (
  fs.reduce((f, g) => (...xs) => f(g(...xs)))
)

export const arity = f => (
  f.length
)

export const nary = (f, n) => (...xs) => (
  f(...xs.slice(n))
)

export const curry = (f, ...xs) => (
  arity(f) <= xs.length
    ? f(...xs)
    : (...ys) => curry(f, ...xs, ...ys)
)

export const uncurry = f => (...xs) => (
  xs.reduce((f, x) => f(x), f)
)

export const reduce = curry((fn, init, xs) => (
  xs.reduce(fn, init)
))

export const map = curry((fn, xs) => (
  xs.map(fn)
))

export const some = curry((fn, xs) => (
  xs.some(fn)
))

export const find = curry((fn, xs) => (
  xs.find(fn)
))
