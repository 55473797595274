const initialState = {
  language: 'nl',
  isMenuOpen: false,
  activeArticle: 1,
  user: 1,
  locale: 'en',
  a: 1,
  b: 1,
  c: 1,
}

export default initialState
