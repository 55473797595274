import { React, styled } from 'x'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: .625em;
  padding: 1em;

  box-shadow:
    0 0.46875em 2.1875em rgba(90, 97, 105, .1),
    0 0.9375em 1.40625em rgba(90, 97, 105, .1),
    0 0.25em 0.53125em rgba(90, 97, 105, .12),
    0 0.125em 0.1875em rgba(90, 97, 105, .1),
    inset 0 .2em 0 0 #007bff;
`

const Header = styled.div`

`

const Body = styled.div`
  padding: 1.875rem;
  text-align: center;
`

const Footer = styled.div`

`

const Card = props => {
  console.log('card:', props)
  return (
    <Container className={ props.className }>
      <Body>
        { props.children }
      </Body>
    </Container>
  )
}

export default Card
