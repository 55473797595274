import { Link, React, styled, mq } from 'x'

const Active = styled.span`
  font-weight: bold;
  color: #FFF;
  text-decoration: none;
  margin: 0 5px;
  
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;
  font-size: 20px;
  position: relative;
  top: 1px;
`

const Wrapper = styled.div`
  color: #FFF;
`

const LanguageLink = styled(Link)`
  color: #FFF;
  opacity: 0.7;
  text-decoration: none;
  margin: 0 5px;
  transition: opacity .2s ease-out;
  
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;
  font-size: 20px;
  position: relative;
  top: 1px;
  
  &:hover {
    opacity: 1;
  }
`

const CTAButtonAfter = styled.div`
  content: '';
  position: absolute;
  left: 0;
  top: -1px; 
  height: 100%;
  border-radius: 60px;
  width: 47px;
  background-color: rgb(255,255,255);
  transition: all .3s ease;
  opacity: 0.4;
  z-index: -1;
  
  ${ mq.below('sm') } {
    width: 45px;
  }
`

const CTAButton = styled.span`
  //margin-right: 40px; // Disabled until language switcher is enabled again
  color: rgba(255,255,255, 0.7);
  position: relative;
  display: inline-block;
  padding: 12px 13px;
  cursor: pointer;
  transition: color .3s ease-out;
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;
  font-size: 19px;
  top: 1px;
  
  &:hover {
    ${ CTAButtonAfter } {
      width: 100%;
    }
  }

  > span {
    color: #FFF;
  }
  
  &:hover {
    color: rgba(255,255,255, 1);
  }
`

const scrollToForm = () => {
  document.getElementById('get-in-touch-form').scrollIntoView({
    behavior: 'smooth'
  })
}

const LanguageSwitcher = ({
  language,
  alternates,
}) => {
  return typeof alternates === 'undefined'
    ? (
      <>
      </>
    )
    : (
      <Wrapper>
        <CTAButton onClick={scrollToForm}>
          <span>GET</span> IN TOUCH
          <CTAButtonAfter id="get-in-touch" />
        </CTAButton>
        {/*{*/}
          {/*language === 'en'*/}
            {/*? <Active>EN</Active>*/}
            {/*: <LanguageLink to={ alternates.en.path }>EN</LanguageLink>*/}
        {/*}*/}
        {/*|*/}
        {/*{*/}
          {/*language === 'nl'*/}
            {/*? <Active>NL</Active>*/}
            {/*: <LanguageLink to={ alternates.nl.path }>NL</LanguageLink>*/}
        {/*}*/}
      </Wrapper>
    )
}

export default LanguageSwitcher
