import { React, styled } from 'x'
import { useState, useEffect, useRef } from 'react'
import { random, randomInteger, randomFloat } from 'utils'
import { noop, map, some, assign, join, split, randomItem } from 'utils'

const setup = ({
  root,
  text,
  delay,
  easing,
  duration,
}) => {
  const startTime = Date.now()

  const state = {
    time: 0,
    frame: 0,
  }

  const scramblers = split('', '__-—/\\|<>')

  const letters = map((letter, index, xs) => {
    const rand = randomFloat(0, 0.5)
    const factor = index / (xs.length - 1)

    console.log(factor)
    return {
      index,
      letter,
      start: factor / 2,
      switch: rand,
      finish: rand + randomFloat(0.01, 0.5),
    }
  }, split('', text))

  const loop = () => {
    assign(state, {
      time: Date.now() - startTime,
      frame: state.frame + 1,
    })

    if (state.time < delay) {
      return requestAnimationFrame(loop)
    }

    const t = (state.time - delay) / duration

    const html = map((letter, index) => (
      letter.finish <= t
        ? `<span class="regular">${ letter.letter }</span>`
        : letter.start <= t
          ? letter.letter === ' '
            ? `<span class="scrambled">${ ' ' }</span>`
            : random() > .1
              ? `<span class="scrambled">${ '-' }</span>`
              : `<span class="scrambled">${ randomItem(scramblers) }</span>`
          : ``
    ), letters)

    root.innerHTML = join('', html)

    if (t >= 1) {
      return
    } else {
      return requestAnimationFrame(loop)
    }

    some(letter => letter.finish >= state.frame, letters)
      ? requestAnimationFrame(loop)
      : noop
  }

  requestAnimationFrame(loop)
}

const Scrambler = ({
  children,
  className,
  text,
  delay = 0,
  duration = 1000,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    setup({
      root: ref.current,
      text,
      delay,
      duration,
    })
  })

  return (
    <div className={ className } ref={ ref }>
      { text }
    </div>
  )
}

const Wrapper = styled(Scrambler)`
  // font-family: monospace;
  font-family: sans-serif;
  font-size: 16px;
  padding: 20px;

  .scrambled {
    color: #aaa;
  }

  .regular {
    color: #fff;
  }
`

export default () => (
  <>
    <Wrapper
      text="Code by design, design by code. Stellar code agency. Assumptions are the mother of all fuck-ups."
      delay={ 0 }
      duration={ 2000 }
    />
    <Wrapper
      text="Code by design, design by code. Stellar code agency. Assumptions are the mother of all fuck-ups."
      delay={ 0 }
      duration={ 1000 }
    />
  </>
)
