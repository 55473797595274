import { default as en } from './en/messages'
import { default as nl } from './nl/messages'

export const locales = {
  en,
  nl,
}

export {
  default as en
} from './en/messages'

export {
  default as nl
} from './nl/messages'

export default locales
