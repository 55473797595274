import { React, styled, Link } from 'x'

import Inno from 'static/svg/logo.svg'

const LogoWrapper = styled.div`
  display: flex;
  margin-right: 50px;
  align-items: center;
`

const Logo = props => (
    <LogoWrapper>
      <Link to="/" aria-label="To the homepage">
        <Inno height={ props.height || 22 } fill={ '#fff' } />
      </Link>
    </LogoWrapper>
)

export default Logo
