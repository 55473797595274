import { React, styled } from 'x'
import { Link } from 'common'

export const A = styled(Link)`
  color: #fff;
`

export const H1 = styled.h1`
  margin: 0 0 10px 0;
  color: #f5f5f5;
  padding-top: 18px;
  padding-bottom: 18px;
`

export const H2 = styled.h2`
  margin: 0;
  color: #FFF;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: .05em;
  padding-top: 18px;
  padding-bottom: 12px;
  font-family: "font-VgXiPuvw", Helvetica, Arial, sans-serif;
`

export const H3 = styled.h3`
  margin: 0;
  color: #FFF;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: .05em;
  padding-top: 6px;
  font-family: "font-VgXiPuvw", Helvetica, Arial, sans-serif;
`

export const H4 = styled.h4`
  margin: 0 0 10px 0;
  color: #FFF;
`

export const H5 = styled.h5`
  margin: 0 0 10px 0;
  color: #FFF;
`

export const H6 = styled.h6`
  margin: 0 0 10px 0;
  color: #FFF;
`

export const Paragraph = styled.p`
  color:  #dcdcdc;
  line-height: 27px;
`

export default {
  a: A,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: Paragraph,
}
