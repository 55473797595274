import { React, Link, styled } from 'x'

const Wrapper = styled.nav`
  position: relative;
  top: 5px;
`

const NavItem = styled(Link)`
  margin: 0 10px;
  color: #FFF;
  text-decoration: none;
`

const Navigation = () => (
  <Wrapper>
    {/*<NavItem to="/">Home</NavItem>*/}
    {/*<NavItem to="/en/golang">Golang</NavItem>*/}
    {/*<NavItem to="/en/wordpress-headless-cms">Wordpress Headless CMS</NavItem>*/}
    {/*<NavItem to="/nl/golang">Golang</NavItem>*/}
    {/*<NavItem to="/demo">Demo</NavItem>*/}
  </Wrapper>
)

export default Navigation