import { fonts } from './typography'
import { colors } from './colors'
import { default as breakpoints } from './breakpoints'

export { fonts } from './typography'
export { colors } from './colors'
export { default as breakpoints } from './breakpoints'

const dimensions = {
  headerHeight: 112,
}

export default {
  ...colors,
  ...fonts,
  ...dimensions,
}
