import { assign } from 'utils'
import { Design } from 'components'
import { locales } from 'locales'
import { initialStoreState } from 'context'
import { React, Helmet, StoreProvider } from 'x'
import { I18nProvider as TranslationProvider } from 'lingui'

const originalConsole = {
  log: console.log.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
}

assign(console, {
  error: (...args) => {
    if (typeof args[0] === 'string' && args[0].startsWith('Warning: useContext() second argument')) {
      return
    }

    originalConsole.error(...args)
  },

  log: (...args) => {
    if (args[0] === 'THREE.WebGLRenderer' && parseInt(args[1], 10) > 90) {
      return
    } else if (typeof args[0] === 'string' && args[0].startsWith('[HMR]')) {
      return
    } else if (args[0] === '[HMR] bundle rebuilding') {
      return
    }

    originalConsole.log(...args)
  },

  warn: (...args) => {
    if (typeof args[0] === 'string' && args[0].startsWith('THREE.WebGLRenderer: image is not power of two')) {
      return
    } else if (typeof args[0] === 'string' && args[0].startsWith('THREE.WebGLRenderer: Texture has been resized from')) {
      return
    }

    originalConsole.warn(...args)
  },
})

const Layout = props => {
  const language = props.pageContext.language

  return (
    <>
      <Helmet
        defaultTitle="Website & App development - inno.dev"
        titleTemplate="%s - inno.dev"
      >
        <meta name="description" content="We turn great ideas into rock-solid digital products"/>
      </Helmet>
      <StoreProvider value={ { ...initialStoreState, language } }>
        <TranslationProvider catalogs={ locales } language={ language }>
          <Design { ...props } />
        </TranslationProvider>
      </StoreProvider>
    </>
  )
}

export default Layout
