import { React, styled, mq } from 'x'

export const H1 = styled.h1`
  margin: 0;
  color: #FFF;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: .05em;
  padding-top: 24px;
  padding-bottom: 30px;
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;

  ${ mq.below('md') } {
    font-size: 30px;
    padding-bottom: 20px;
    letter-spacing: .03em;
  }
`

export const H2 = styled.h2`
  margin: 0;
  color: #FFF;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: .05em;
  padding-top: 24px;
  padding-bottom: 30px;
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;

  ${ mq.below('sm') } {
    font-size: 30px;
    padding-bottom: 20px;
  }
`

export const H3 = styled.h3`
  margin: 0;
  color: #FFF;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: .05em;
  font-family: "font-VgXiPuvw",Helvetica,Arial,sans-serif;

  ${ mq.below('sm') } {
    font-size: 20px;
  }
`

export const H4 = styled.h4`
  margin: 0 0 10px 0;
  color:  #6495ED;
`

export const H5 = styled.h5`
  margin: 0 0 10px 0;
  color:  #6495ED;
`

export const H6 = styled.h6`
  margin: 0 0 10px 0;
  color:  #6495ED;
`
