import { styled, mq } from 'x'

const Content = styled.div`
  max-width: 1366px;
  padding: 0 50px;
  margin: 0 auto;
  
  ${ mq.below('sm') } {
    max-width: 100%;
    padding: 0 20px;
  }
`

export default Content