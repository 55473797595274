import { curry } from 'utils'

export const toString = x => (
  String(x)
)

export const trim = str => (
  String(str).trim()
)

export const split = curry((seperator, str) => (
  String(str).split(seperator)
))

export const capitalize = str => (
  str[0].toUpperCase() + str.substring(1)
)

export const uncapitalize = str => (
  str[0].toLowerCase() + str.substring(1)
)

export const toUpperCase = str => (
  str.toUpperCase()
)

export const toLowerCase = str => (
  str.toLowerCase()
)

export const stripTags = str => (
  str.replace(/<[^>]*>/g, '')
)

export const splitLines = str => (
  str.split(/\r?\n/)
)

export const pad = (str, length, char = ' ') => (
	str.padStart((str.length + length) / 2, char).padEnd(length, char)
)

export const replaceRange = (str, start, end, substitute) => (
	str.substring(0, start) + substitute + str.substring(end)
)

export const stripPrefix = (str, prefix) => (
	str.indexOf(prefix) === 0 ? str.substr(prefix.length) : str
)

export const camelToSlug = str => (
	str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
)

export const camelToSnail = str => (
	str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase()
)

export const camelToSnake = str => (
	str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toUpperCase()
)

export const slugToCamel = str => (
  str.replace(/-([a-z])/g, part => part[1].toUpperCase())
)
