import { default as Typed } from 'typed.js'
import { useRef, useEffect } from 'react'
import { React, Parallax, styled, keyframes, mq } from 'x'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40%) translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(-60%) translateX(-50%);
  }
`


const IDE = styled.div`
  background: #282A36;
  padding: 40px 15px 15px;
  border-radius: 8px;
  position: absolute;
  top: 100vh;
  left: 50%;
  transform: translateX(-50%) translateY(-60%);
  z-index: 2;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  width: 60%;
  max-width: 700px;
  animation: ${ fadeIn } 1s 1s ease-out 1 both;
  
  ${ mq.below('sm') } {
    display: none;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
`

const Control = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
  margin-right: 8px;
`

const Close = styled(Control)`
  background-color: #FD605B;
`

const Minimize = styled(Control)`
  background-color: #FEBC40;
`

const Fullscreen = styled(Control)`
  background-color: #33C748;
`

const IDEWrapper = styled.div`
  position: relative;
  font-size: 16px;
  font-family: Inconsolata;
  color: #FFF;
`

const IDEContent = styled.div`
  min-height: 230px;
  min-width: 300px;
  line-height: 23px;
`

const InputWrapper = styled.div`
  z-index: 2;
  box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.3);
  background: #282A36;
  padding: 15px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-family: Inconsolata;
  color: #FFF;
  min-width: 50px;
`

const InputConnector = styled.div`
  z-index: 1;
  padding: 15px 15px;
  position: absolute;
  top: ${ props => props.top }px;
  left: ${ props => props.left }px;
  min-width: 50px;

  ::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background: #fff;
    top: 88px;
    left: 95px;
    border: 4px solid #282a35;
  }

  ::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 47px;
    top: 45px;
    left: 40px;
    z-index: -1;
    border-left: 4px solid #282A35;
    border-bottom: 4px solid #282A35;
    border-radius: 0 0 0 10px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, .3);
  }
`

const InputLabel = styled.div`
  color: #BABABA;
  font-size: 14px;
  margin-bottom: 3px;
`

const InputValue = styled.span``

const OutputWrapper = styled.div`
  position: absolute;
  top: ${ props => (props.line - 1) * 23 }px;
  right: 0;
  width: 123px;
  line-height: 23px;
  color: #7d7d7d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  /*
  border: 1px solid #4A4B52;
  background: #30323C;
  */
`

const OutputLabel = styled.span`

`

const ColorPreview = styled.div`
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 3px;
`

const HSL = styled.span`
  display: inline-block;
  width: 25px;
  text-align: center;
`

const Input = ({ label, top = 0, left = 0, children }) => {
  return (
    <>
      <Parallax
        y={ ['-20px', '20px'] }
        styleOuter={{
          zIndex: 2,
          position: 'absolute',
          top,
          left,
        }}
      >
        <InputWrapper top={ top } left={ left }>
          <InputLabel>{ label }</InputLabel>
          <>
            { children }
          </>
        </InputWrapper>
      </Parallax>
      <InputConnector top={ top } left={ left } />
    </>
  )
}

const Output = ({ label, line, children }) => {
  return (
    <OutputWrapper line={ line }>
      <OutputLabel>{ label }</OutputLabel>
      <>
        { children }
      </>
    </OutputWrapper>
  )
}

const Code = styled.span`
  ${ props => props.tabs
    ? `margin-left: ${ props.tabs * 2 }ch;`
    : ''
  }
`

const White = styled(Code)`
  color: #fff;
`

const Pink = styled(Code)`
  color: #FD7CC5;
`

const Purple = styled(Code)`
  color: #9A89FA;
`

const Gold = styled(Code)`
  color: #FECB9C;
`

const Yellow = styled(Code)`
  color: #F1F992;
`

const Sky = styled(Code)`
  color: #6BD9ED;
`

export default props => {
  const source = useRef()
  const target = useRef()

  useEffect(() => {
    const typed = new Typed(target.current, {
      stringsElement: source.current,
      contentType: 'html',
      autoInsertCss: true,
      showCursor: false,
      startDelay: 2000,
      typeSpeed: 17,
    })

  }, [])

  return (
    <IDE>
      <Controls>
        <Close />
        <Minimize />
        <Fullscreen />
      </Controls>
      <Input
        label="time"
        top={ 50 }
        left={ -100 }
        children={ <InputValue id="global-time">4210.30</InputValue> }
      />
      <IDEWrapper>
        <Output
          line={ 6 }
          label="hue"
          children={ <HSL id="hsl">300</HSL> }
        />
        <Output
          line={ 7 }
          label="color"
          children={ <ColorPreview id="color-preview"></ColorPreview> }
        />

        <IDEContent ref={ target } />
        <IDEContent ref={ source }>
          <p>
            <Pink>import</Pink>
            { ' ' }
            <White>{ '{ hsl }' }</White>
            { ' ' }
            <Pink>from</Pink>
            { ' ' }
            <Yellow>'color'</Yellow>
            <br />
            <br />
            <Pink>const</Pink>
            { ' ' }
            <White>{ '{ abs, sin }' }</White>
            { ' ' }
            <Pink>=</Pink>
            { ' ' }
            <White>Math</White>
            <br />
            <br />
            <Pink>const</Pink>
            { ' ' }
            <Gold>drawAurora</Gold>
            { ' ' }
            <Pink>=</Pink>
            { ' ' }
            <White>(</White>
            <Sky>time</Sky>
            <White>,</White>
            { ' ' }
            <Sky>options</Sky>
            <White>)</White>
            { ' ' }
            <Pink>=></Pink>
            { ' ' }
            <White>{ '{' } </White>
            <br />
            <Pink tabs={ 1 }>const</Pink>
            { ' ' }
            <Gold>hue</Gold>
            { ' ' }
            <Pink>=</Pink>
            { ' ' }
            <White>abs(sin((</White>
            <Sky>time</Sky>
            {' '}
            <Pink>+</Pink>
            {' '}
            <Purple>105</Purple>
            <White>)</White>
            {' '}
            <Pink>/</Pink>
            {' '}
            <Purple>30</Purple>
            <White>))</White>
            {' '}
            <Pink>*</Pink>
            { ' ' }
            <Purple>359</Purple>
            <br />
            <Pink tabs={ 1 }>const</Pink>
            { ' ' }
            <Gold>color</Gold>
            { ' ' }
            <Pink>=</Pink>
            { ' ' }
            <White>hsl(</White>
            <Gold>hue</Gold>
            <White>,</White>
            { ' ' }
            <Yellow>'100%'</Yellow>
            <White>,</White>
            <Yellow>'80%'</Yellow>
            <White>)</White>
            <br />
            <br />
            <Pink tabs={ 1 }>return</Pink>
            { ' ' }
            <White>updateAurora(</White>
            <Gold>color</Gold>
            <White>)</White>
            <br />
            <White>}</White>
          </p>
        </IDEContent>
      </IDEWrapper>
    </IDE>
  )
}
