import * as Yup from 'yup'

import { default as breakpoints } from 'styles/breakpoints'
import { initialStoreState } from 'context'

import { createStore, createMediaQueries } from 'library'

export const mq = createMediaQueries(breakpoints)

export const {
  StoreProvider,
  useStore,
  withStore,
} = createStore(initialStoreState)

export {
  default as React,
} from 'react'

export {
  default as styled,
  css,
  keyframes
} from 'styled-components'

export {
  Link,
} from 'common'

export {
  navigate,
  withPrefix,
  StaticQuery
} from 'gatsby'

export {
  default as Helmet,
} from 'react-helmet'

export {
  default as Image,
} from 'gatsby-image'

export {
  Parallax
} from 'react-scroll-parallax'

export {
  Field,
  Formik,
} from 'formik'

export { Yup as Yup }
