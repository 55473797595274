import { Label, H2, Content, Button } from 'common'
import { useReveal, useRef, useEffect, useState, useCallback } from 'hooks'
import { React, Formik, Field, Yup, styled, keyframes, mq, css } from 'x'

const FormWrapper = styled.div`
  margin-top: 50px;
  padding: 75px 0;
  background: #262626;
`

const StyledContent = styled(Content)`
  display: flex;
  justify-content: space-between;

  ${mq.below('sm')} {
    flex-flow: column;
  }
`

const Input = styled.input`
  font-family: 'font-VgXiPuvw', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 20px 0;
  line-height: 20px;
  letter-spacing: 0.1em;
  border: 0;
  outline: 0;
  background: none;
  color: #fafafa;
  width: 100%;
  &:invalid {
    box-shadow: none;
  }
`

const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 50%;
  max-width: 600px;
  position: relative;

  ${mq.below('sm')} {
    width: 100%;
    margin-top: 30px;
  }
`

const LineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`

const Line = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  overflow: hidden;
  background-color: #909090;
`

const ErrorLine = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 0;
  height: 2px;
  overflow: hidden;
  background-color: rgba(255, 0, 0, 0.5);

  animation-name: ${(props) => (props.hasError ? LineAnimation : 'none')};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`

const FieldWrapper = styled.div`
  position: relative;
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  opacity: 0;
  transform: translateY(50px);

  ${(props) => props.isRevealed && 'transform: translateY(0px); opacity: 1.0;'}

  ${Line} {
    transition: transform 1s ease-out;
    transform-origin: top left;

    ${(props) => (props.isRevealed ? 'transform: scaleX(1);' : 'transform: scaleX(0);')}
  }
`

const TextArea = styled(Field)`
  height: 150px;
  margin-top: 26px;
  resize: none;
  font-family: 'font-VgXiPuvw', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  padding: 0 0 20px;
  line-height: 20px;
  letter-spacing: 0.1em;
  border: 0;
  outline: 0;
  background: none;
  color: #fafafa;
  width: 100%;
`

const ContactRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const ContactLabel = styled.div`
  color: #fff;
  font-size: 16px;
  width: 80px;
  line-height: 25px;
`

const Email = styled.a`
  color: #8c8c8c;
  font-size: 16px;
  text-decoration: none;
  line-height: 25px;
`

const ContactAddress = styled.div`
  color: #8c8c8c;
  font-size: 16px;
  line-height: 25px;
`

const Submit = styled(Button)`
  margin-top: 40px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;

  ${(props) => props.isRevealed && 'transform: translateY(0px); opacity: 1.0;'}
`

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const SuccessOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #262626;
  z-index: 1;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  color: #fff;
  ${(props) =>
    props.show &&
    css`
      animation: ${fadeInAnimation} 1s ease-out 1 forwards;
    `}
`

const initialValues = {
  name: '',
  email: '',
  information: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please fill in your name'),
  email: Yup.string().email('Please fill in a valid email address').required('Please fill in your email address'),
  information: Yup.string().required('Please tell us about your project'),
})

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const GetInTouch = () => {
  const [success, setSuccess] = useState(false)
  const [startTime, setStartTime] = useState(null)

  const { reveal: revealName, isRevealed: isNameRevealed } = useReveal({ delay: 0 })
  const { reveal: revealEmail, isRevealed: isEmailRevealed } = useReveal({ delay: 250 })
  const { reveal: revealMessage, isRevealed: isMessageRevealed } = useReveal({ delay: 500 })
  const { reveal: revealSubmit, isRevealed: isSubmitRevealed } = useReveal({ delay: 750 })

  const email = useRef(null)

  useEffect(() => {
    const address = 'hello' + '@' + 'inno.dev'

    email.current.textContent = address
    email.current.href = 'mailto:' + address
  }, [])

  const onSubmit = useCallback(
    async (values) => {
      const hash = (str) =>
        crypto.subtle
          .digest('SHA-512', new TextEncoder('utf-8').encode(str))
          .then((buf) => Array.prototype.map.call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2)).join(''))

      const validationHash = await hash('inno' + values.email)
      const now = new Date()
      const diff = now.getTime() - startTime.getTime()
      const seconds = diff / 1000
      const message = `${values.information}\n\nUserAgent: ${window.navigator.userAgent}\nSeconds spent filling in form: ${seconds}`

      if (values.information.split(' ').length === 1) {
        setSuccess(true)
        return
      }

      // Coming here means form validation is successful
      try {
        await fetch('https://inno-web-contact.fly.dev/submit', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            message,
            validation_hash: validationHash,
          }),
        })
        setSuccess(true)
      } catch (error) {
        console.log('form submit error', error)
        alert('Something went wrong submitting the form, our apologies, please try again.')
      }
    },
    [startTime],
  )

  return (
    <FormWrapper id="get-in-touch-form">
      <StyledContent>
        <div>
          <Label>Contact</Label>
          <H2>Let’s discuss your project</H2>
          <div>
            <ContactRow>
              <ContactLabel>Email</ContactLabel>
              <Email ref={email} />
            </ContactRow>
            <ContactRow>
              <ContactLabel>Address</ContactLabel>
              <ContactAddress>
                Hannie Dankbaarpassage 14
                <br />
                1053 RT Amsterdam
                <br />
                The Netherlands
              </ContactAddress>
            </ContactRow>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validate={() => {
            if (!startTime) {
              setStartTime(new Date())
            }
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} method="POST" name="get-in-touch">
              <SuccessOverlay show={success}>Thank you for contacting us, we'll be in touch as soon as possible.</SuccessOverlay>
              <div>
                <FieldWrapper isRevealed={isNameRevealed} ref={revealName}>
                  <Input
                    aria-label="Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder={'YOUR NAME'}
                  />
                  <Line />
                  <ErrorLine hasError={errors.name && touched.name} />
                </FieldWrapper>
              </div>
              <div>
                <FieldWrapper isRevealed={isEmailRevealed} ref={revealEmail}>
                  <Input
                    aria-label="Email address"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder={'Email address'}
                  />
                  <Line />
                  <ErrorLine hasError={errors.email && touched.email} />
                </FieldWrapper>
              </div>
              <div>
                <FieldWrapper isRevealed={isMessageRevealed} ref={revealMessage}>
                  <TextArea aria-label="Information" name="information" component="textarea" placeholder="Message" />
                  <Line />
                  <ErrorLine hasError={errors.information && touched.information} />
                </FieldWrapper>
              </div>
              <Submit type="submit" disabled={isSubmitting} ref={revealSubmit} isRevealed={isSubmitRevealed}>
                Submit
              </Submit>
            </Form>
          )}
        </Formik>
      </StyledContent>
    </FormWrapper>
  )
}

export default GetInTouch
