import { React, Link, styled } from 'x'

export const Paragraph = styled.p`
  color:  #dcdcdc;
  line-height: 22px;
`

export const A = styled(props => <Link {...props} />)`
  color: #fff;
`
