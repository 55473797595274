import { React } from 'x'
import { pipe, withState } from 'rehook'
import { reduce, capitalize, toKeys } from 'utils'
import { createContext, useContext } from 'react'

export default initialState => {
  const initialKeys = toKeys(initialState)

  if (initialKeys > 11) {
    throw new Error('Ask Wesley why, he will explain')
  }

  const toBits = (bits, key) => {
    const index = initialKeys.indexOf(key)
    return bits | 1 << index
  }

  const toState = (out, key, index) => (
    [...out, withState((key), `set${ capitalize(key) }`, initialState[key])]
  )

  const calculateChangedBits = (prev, next) => {
    const changedBits = reduce((bits, key, index) =>
      prev[key] !== next[key]
        ? bits |= 1 << index
        : bits
    , 0, initialKeys)

    return changedBits
  }

  const Context = createContext(initialState, calculateChangedBits)

  const states = reduce(toState, [], initialKeys)

  const StoreProvider = pipe(
    ...states,
    ({ children, ...props }) => (
      <Context.Provider value={ props }>
        { children }
      </Context.Provider>
    )
  )

  const useStore = (...keys) => {
    const observedBits = reduce(toBits, 0, keys)
    const store = useContext(Context, observedBits)

    return store
  }

  const withStore = (...keys) => {
    const observedBits = reduce(toBits, 0, keys)

    return props => {
      const store = useContext(Context, observedBits)

      return { ...props, store }
    }
  }

  return {
    StoreProvider,
    useStore,
    withStore,
  }
}
